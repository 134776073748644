<template>
  <v-footer color="primary">
    <v-container fluid>
      <v-layout
        row
        wrap
        :class="{ contentPadding: $vuetify.breakpoint.mdAndUp }"
      >
        <v-flex xs12 md6>
          <a
            :href="nswEducationUrl"
            aria-label="NSW Government"
            target="_blank"
          >
            <v-img
              class="logo"
              width="84"
              height="84"
              src="@/assets/logo.svg"
              alt="NSW Government"
            />
          </a>
        </v-flex>
        <v-flex xs12 md3 :class="{ 'mt-4': $vuetify.breakpoint.smAndDown }">
          <div class="heading">Privacy</div>
          <div>
            <a
              href="https://education.nsw.gov.au/about-us/rights-and-accountability/privacy/privacy-information-and-forms"
              target="_blank"
              ><v-icon class="pr-3">mdi-lock-outline</v-icon>Privacy
              Legislation</a
            >
          </div>
        </v-flex>
        <v-flex xs12 md3 :class="{ 'mt-4': $vuetify.breakpoint.smAndDown }">
          <div class="heading">Technical support</div>
          <div class="mt-2 mb-2">
            <a
              href="https://education.nsw.gov.au/teaching-and-learning/curriculum/multicultural-education/interpreting-and-translations"
              target="_blank"
            >
              <v-icon class="pr-3">language</v-icon>Translation services
            </a>
          </div>
          <div class="mb-2">
            <a
              href="https://enrolmentassist.freshdesk.com/support/tickets/new"
              target="_blank"
            >
              <v-icon class="pr-3">description</v-icon>Use our contact form
            </a>
          </div>
          <div class="mb-2">
            <a href="tel:1300679338">
              <v-icon class="pr-3">phone_iphone</v-icon>Phone 1300 679 338
            </a>
          </div>
          <div class="mb-2">
            <div>
              <span class="ml-9">Monday - Friday, 7:30am - 6:00pm</span>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-footer>
</template>

<script>
import { NSW_EDUCATION_URL } from '@/constants'

export default {
  name: 'OoaFooter',
  data() {
    return {
      nswEducationUrl: NSW_EDUCATION_URL
    }
  }
}
</script>

<style lang="scss" scoped>
.v-footer {
  color: $ads-white;
  height: 200px;
  .heading {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1rem !important;
  }
  a {
    color: $ads-white;
    font-weight: normal;
    text-decoration: none;
  }
}
.theme--light.v-icon {
  color: $ads-white;
}
.mobile .v-footer {
  height: auto;
}
</style>
