<template>
  <!-- eslint-disable max-len -->
  <v-container class="pa-0" data-testid="sibling-details-section">
    <div v-if="!form.hasAnySiblings">
      <div class="noSibling">No siblings</div>
    </div>
    <div v-if="form.hasAnySiblings">
      <div v-for="(sibling, index) in form.siblings" :key="index" class="mb-3">
        <div class="siblingName mb-2">
          Sibling {{ index + 1 }}:
          {{
            sibling.siblingsFirstName ? sibling.siblingsFirstName : '(empty)'
          }}
          {{ sibling.siblingsFamilyName }}
        </div>
        <v-row>
          <v-col>
            <div class="fieldLabel">Given name</div>
            <div class="fieldValue">
              {{
                sibling.siblingsFirstName
                  ? sibling.siblingsFirstName
                  : '(empty)'
              }}
            </div>
          </v-col>
          <v-col>
            <div class="fieldLabel">Middle name</div>
            <div class="fieldValue">
              {{
                sibling.siblingsOtherName
                  ? sibling.siblingsOtherName
                  : '(empty)'
              }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="fieldLabel">Family name</div>
            <div class="fieldValue">
              {{
                sibling.siblingsFamilyName
                  ? sibling.siblingsFamilyName
                  : '(empty)'
              }}
            </div>
          </v-col>
          <v-col>
            <div class="fieldLabel">Gender</div>
            <div class="fieldValue">
              {{
                genderCode(sibling.siblingsGenderCode)
                  ? genderCode(sibling.siblingsGenderCode)
                  : '(empty)'
              }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="fieldLabel">Date of birth</div>
            <div class="fieldValue">
              {{ sibling.siblingsDOB ? sibling.siblingsDOB : '(empty)' }}
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SiblingsView',
  computed: {
    ...mapGetters({
      form: 'form'
    })
  },
  methods: {
    genderCode(code) {
      if (code) {
        return code === 'M' ? 'Male' : 'Female'
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.fieldValue {
  color: $color-text-body;
  font-weight: bold;
  font-size: 14px;
  overflow-wrap: break-word;
}
.fieldLabel {
  font-size: 14px;
  color: $ads-dark-70;
}
.siblingName {
  border-bottom: 1px solid $ads-light-20;
  font-weight: bold;
  font-size: 16px;
  color: $color-text-body;
  overflow-wrap: break-word;
}
.noSibling {
  font-weight: bold;
  font-size: 16px;
  color: $color-text-body;
}
</style>
