<template>
  <!-- eslint-disable max-len -->
  <v-container class="pt-0" aria-live="polite">
    <strong v-if="isOverCap"
      >We are not considering applications for out-of-area enrolments.</strong
    >
    <p class="mb-4 heading">
      {{ heading }}
      <span v-if="hasMedicalReasons"
        >You can upload supporting documents about medical conditions here or
        present them at the school.</span
      >
    </p>

    <template v-if="!isOverCap">
      <p class="mb-5">
        When considering this application, we may ask for more information,
        supporting documents, or arrange an interview.
      </p>
      <p v-if="hasMedicalReasons" class="mb-10">
        Learn more about how we
        <a
          href="https://education.nsw.gov.au/about-us/rights-and-accountability/privacy/privacy-information-and-forms"
          target="_blank"
          >manage and protect your personal information</a
        >
      </p>
    </template>

    <v-form
      v-if="isOverCap"
      ref="overCapForm"
      v-model="validConsiderations"
      data-testid="overcapacity-form"
      aria-live="polite"
    >
      <v-card class="pa-6">
        <div class="ml-1 mt-2 mb-6">
          Please outline any special circumstances that relate to your child's
          out-of-area application.
        </div>
        <TextArea
          v-model="form.otherConsiderations"
          data-initial-focus
          class="mb-6"
          label="Provide details"
          placeholder=" "
          :rules="[rules.mandatory, rules.maxLength]"
          :counter="counter"
          data-testid="otherConsiderations"
        />
        <div class="ml-1 mt-n4 considerations-hint">
          When considering this application, we may ask for more information,
          supporting documents, or arrange an interview.
        </div>
      </v-card>
      <v-card v-if="hasCaptureSiblings" class="pa-6 mt-8">
        <Siblings ref="siblingForm" />
      </v-card>
    </v-form>

    <v-form
      v-else
      ref="nonOverCapForm"
      v-model="validConsiderations"
      data-testid="non-overcapacity-form"
      aria-live="polite"
    >
      <v-card class="pl-6 pr-6 pt-2">
        <div v-for="(criteria, index) in criteriasActive" :key="index">
          <v-checkbox
            v-model="criteria.selected"
            :data-initial-focus="index === 0"
            class="priority"
            data-testid="criteria-checkbox"
            @change="checkCriteria(criteria)"
          >
            <template #label>
              <div>
                Priority {{ index + 1 }}: <strong>{{ criteria.value }}</strong>
              </div>
            </template>
          </v-checkbox>
          <div
            v-if="criteria.text && criteria.selected"
            class="ml-7 mb-5 criteria-text"
          >
            {{ criteria.text }}
          </div>
          <Siblings
            v-if="criteria.code === 'SIB' && criteria.selected"
            ref="siblingForm"
            class="ml-4"
          />
          <TextArea
            v-if="criteria.selected"
            v-model="criteria.description"
            class="pb-6 mt-3 ml-6"
            :label="
              criteria.code === 'SIB'
                ? 'Further details (optional)'
                : 'Provide details'
            "
            placeholder=" "
            :rules="
              criteria.code === 'SIB'
                ? [rules.maxLength]
                : [rules.mandatory, rules.maxLength]
            "
            :counter="counter"
            data-testid="criteria-description"
          />
          <SupportingDocumentUpload
            v-if="criteria.code === 'MED' && criteria.selected"
            ref="supportingDocumentUpload"
            :value="files"
            class="ml-6 pb-10"
            :category="criteria.code"
            :mime-types="criteria.supportingDocConfig.supportedMIMETypes"
            :hint="criteria.supportingDocConfig.helpText"
            :file-limit="criteria.supportingDocConfig.fileLimit"
            :size-limit="criteria.supportingDocConfig.sizeLimitMB"
            @input="onFileUpload"
          />
          <div v-if="index + 1 !== criteriasActive.length" class="criteria" />
        </div>
        <div v-if="form.criteria">
          <div
            v-for="(criteria, index) in criteriasInactivedPreviouslySaved"
            :key="index"
          >
            <div class="criteria" />
            <v-row>
              <v-col cols="1">
                <v-icon disabled>mdi-block-helper</v-icon>
              </v-col>
              <v-col cols="11">
                <div class="disabled-consideration">
                  <strong>Note: </strong
                  ><span
                    >"{{ criteria.value }}" is no longer assessed for
                    out-of-area enrolment at this school</span
                  >
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>
      <div v-if="showUnderCapOtherConsiderations">
        <p class="mt-8 mb-4">
          Applications are assessed on the criteria above. Are there other
          considerations relevant to this application?
        </p>
        <v-card class="pa-8">
          <TextArea
            v-model="form.otherConsiderations"
            label="Other considerations"
            placeholder=" "
            :rules="maxLength"
            :counter="counter"
            data-testid="criteria-otherConsiderations"
          />
        </v-card>
      </div>
    </v-form>

    <div class="d-flex justify-end mt-6">
      <v-btn
        xclass="button-next primary"
        color="primary"
        x-large
        data-testid="save-criteria-btn"
        @click="
          enableLoader()
          save()
        "
      >
        <ButtonSpinner :loading="loading" class="mr-2" />
        Save &amp; Continue
      </v-btn>
    </div>
  </v-container>
</template>

<script>
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["criteria"] }] */
import TextArea from '@/components/form/TextArea'
import ButtonSpinner from '@/components/ButtonSpinner'
import Siblings from '@/components/Siblings'
import SupportingDocumentUpload from '@/components/SupportingDocumentUpload'
import { mapGetters } from 'vuex'
import handleFirstFocus from '@/helpers/form/focus'
import { FILE_UPLOAD_ERROR, FILE_REMOVE_ERROR } from '@/constants'

export default {
  name: 'FormConsiderations',
  components: {
    TextArea,
    ButtonSpinner,
    Siblings,
    SupportingDocumentUpload
  },
  data() {
    return {
      loading: false,
      details: null,
      counter: 800,
      validConsiderations: true,
      criterias: [],
      rules: {
        mandatory: (v) => !!v || 'Please provide details',
        maxLength: (v) => !v || v.length <= 800 || 'Max 800 characters'
      },
      maxLength: [(v) => !v || v.length <= 800 || 'Max 800 characters'],
      isSectionCommitted: false,
      files: []
    }
  },
  computed: {
    ...mapGetters([
      'form',
      'states',
      'currentStep',
      'schoolData',
      'isOverCap',
      'pluralisedStudentName',
      'supportingDocuments',
      'prevCriterias'
    ]),
    heading() {
      let headingText = `Provide details about any of the criteria below that support ${this.pluralisedStudentName()} application.`
      if (this.hasCaptureSiblings) {
        headingText =
          'However, we may take into account exceptional circumstances that involve this student and if siblings are enrolled at our school.'
      } else if (this.isOverCap) {
        headingText =
          'However, we may take into account exceptional circumstances that involve this student'
      }
      return headingText
    },
    hasMedicalReasons() {
      const criteria = this.criterias.find((c) => c.code === 'MED')
      return !!criteria && criteria.active
    },
    criteriasActive() {
      return this.criterias.filter((criteria) => criteria.active)
    },
    criteriasInactivedPreviouslySaved() {
      const savedCriteriaCodes = this.form.criteria.map(({ code }) => code)
      return this.criterias.filter(
        (criteria) =>
          !criteria.active && savedCriteriaCodes.includes(criteria.code)
      )
    },
    showUnderCapOtherConsiderations() {
      return this?.schoolData?.enableOtherCriteria
    },
    hasCaptureSiblings() {
      return this.isOverCap && !(this.schoolData?.captureSiblingInfo === false)
    }
  },
  mounted() {
    this.files = this.form.supportingDocuments?.MED || []
    if (this.prevCriterias) {
      this.criterias.forEach((criteria) => {
        this.prevCriterias.forEach((prevCriteria) => {
          if (
            criteria.code === prevCriteria.code &&
            prevCriteria.selected === true
          ) {
            criteria.selected = true
            criteria.description = prevCriteria.description
          }
        })
      })
    }

    if (this.form.criteria) {
      this.validate()
    }

    this.$nextTick(() => {
      // combine the considerations and siblings form inputs for the first error focusing
      const formInputs = this.isOverCap
        ? this.$refs.overCapForm.inputs
        : this.$refs.nonOverCapForm.inputs
      const siblingsFormInputs =
        this.$refs.siblingForm?.$refs?.siblingsForm.inputs
      if (siblingsFormInputs) {
        formInputs.push(...siblingsFormInputs)
      }
      handleFirstFocus(formInputs)
    })
  },
  created() {
    // Load reference data criteria & create default array of criteria objects with a code & value
    if (!this.isOverCap && this.schoolData) {
      // eslint-disable-next-line
      this.criterias = this.schoolData.criteria.map((c) => {
        return {
          selected: null,
          code: c.code,
          value: c.value,
          description: null, // the value entered by the user. Flows through to SI! Don't change!
          text: c.text, // criteria description set in SI settings
          active: c.active,
          supportingDocConfig: c.supportingDocConfig
        }
      })
    }

    // Set description data returned by API against each criteria.
    if (this.form.criteria) {
      const savedCriterias = this.form.criteria
      savedCriterias.forEach((item) => {
        this.criterias.forEach((criteria) => {
          if (criteria.code === item.code) {
            criteria.selected = true
            criteria.description = item.description
          }
        })
      })
    }
  },
  beforeDestroy() {
    this.commitSection()
  },
  methods: {
    enableLoader() {
      this.loading = true
    },
    disableLoader() {
      this.loading = false
    },
    validate() {
      let isValid
      if (this.isOverCap) {
        isValid =
          this.$refs.overCapForm.inputs.filter(
            (input) =>
              !input.form.$el.className.includes('ooa-form') &&
              !input.validate(true)
          ).length === 0
      } else {
        isValid = this.$refs.nonOverCapForm.validate()
      }

      this.$store.dispatch('setIsValidConsiderations', { isValid })
    },
    getCriteriaById(criteriaId) {
      return this.criterias.find((criteria) => criteria.code === criteriaId)
    },
    onFileUpload(files) {
      this.files = files
      this.$store.commit('setSupportingDocuments', { MED: files })
    },
    checkCriteria(criteria) {
      if (criteria.selected) {
        if (
          this.prevCriterias?.forEach((prevCriteria) => {
            if (
              prevCriteria.code === criteria.code &&
              prevCriteria.description
            ) {
              criteria.description = prevCriteria.description
            }
          })
        ) {
          if (criteria.description) {
            this.$store.commit('setSelectedPrevCriteria', criteria)
          }
        }
      }
      if (criteria.code === 'SIB') {
        this.$store.commit('setHasAnySiblings', criteria.selected)
      }
    },
    commitSection() {
      if (!this.isSectionCommitted) {
        if (!this.isOverCap) {
          const sibCriteria = this.getCriteriaById('SIB')
          if (sibCriteria) {
            this.$store.commit('setHasAnySiblings', sibCriteria.selected)
          }
          const chkedList = this.criterias.filter(
            (criteria) => criteria.selected && criteria.active
          )
          const criteria = chkedList.map((item) => ({
            code: item.code,
            description: item.description
          }))
          this.$store.commit('setCriteria', criteria)
          if (!this.showUnderCapOtherConsiderations) {
            this.form.otherConsiderations = null
          }
        } else {
          this.$store.commit('setCriteria', [])
        }
        this.$store.commit('setCapacityDetails', this.schoolData.capacity)
        this.$store.commit('setOverCapDetails', this.form.otherConsiderations)
        this.isSectionCommitted = true
        this.validate()
      }
    },
    async save() {
      try {
        this.$store.commit('setPrevCriterias', this.criterias)
        if (this.$store.getters.isFileUploadInProgress) {
          this.$emit('notification', FILE_UPLOAD_ERROR)
        } else if (this.$store.getters.isFileDeleteInProgress) {
          this.$emit('notification', FILE_REMOVE_ERROR)
        } else {
          this.validate()
          this.commitSection()
          // for undercap schools saving the sibling details for the
          const sibCriteria = this.getCriteriaById('SIB')
          if (sibCriteria && sibCriteria.selected) {
            await this.$refs.siblingForm[0].save()
          }
          await this.$store.dispatch('updateApplication')
          await this.$store.dispatch('setCurrentStep', {
            currentStep: this.currentStep + 1
          })
          await this.$router.push('/form/review')
        }
      } finally {
        this.disableLoader()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  color: $ads-navy;
  font-size: 1.375rem;
  line-height: 1.2;
}
.criteria {
  margin-right: -24px;
  margin-left: -24px;
  border-bottom: 1px solid #e4e4e6;
}
::v-deep .priority .theme--light.v-label {
  color: $color-text-body;
}
.heading {
  overflow-wrap: break-word;
}
.disabled-consideration {
  color: $ads-dark-60;
}

.considerations-hint {
  font-size: 0.875rem;
  color: $ads-dark-60;
}
</style>
