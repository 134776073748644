import store from '@/store'
import { removeSessionItem } from '@/helpers/storage'
import jwtDecode from 'jwt-decode'
import { ERROR_CODES } from '@/constants'
import { isFromEhub } from '@/helpers'

export default function form(to, from, next) {
  // helper functions to resume application
  function getItemFromSessionStorage(key) {
    const value = window.sessionStorage.getItem(key)
    if (value) {
      return JSON.parse(value)
    }
    return ''
  }

  function getItemsFromSessionStorage(keys) {
    return keys.reduce(
      (acc, key) => ({ ...acc, [key]: getItemFromSessionStorage(key) }),
      {}
    )
  }

  async function fetchApplication() {
    await store
      .dispatch('fetchApplication')
      .then(() => {
        if (!store.getters.isPendingApplication) {
          store.dispatch('setErrorType', { errorType: ERROR_CODES.NO_PENDING })
          next({ name: 'error' })
        }
      })
      .catch(() => {
        store.dispatch('setErrorType', { errorType: ERROR_CODES.NO_PENDING })
        next({ name: 'error' })
      })
  }

  function setStore(idToken, refreshToken, email, schoolData, parentId) {
    store.commit('setIdToken', idToken)
    store.commit('setRefreshToken', refreshToken)
    store.commit('setSchool', schoolData)
    store.commit('setViaEhub', true)
    store.commit('setEmail', email)
    store.commit('setParentId', parentId)
  }

  async function resumeApplication() {
    const { idToken, refreshToken, schoolData } = getItemsFromSessionStorage([
      'idToken',
      'refreshToken',
      'schoolData'
    ])
    if (idToken && refreshToken && schoolData) {
      const { email } = jwtDecode(idToken)
      const parentId = jwtDecode(idToken)['custom:pid']
      setStore(idToken, refreshToken, email, schoolData, parentId)
      await fetchApplication()
      await store.dispatch('initReferenceData', schoolData.schoolCode)
      next()
    } else {
      await store.dispatch('setErrorType', {
        errorType: ERROR_CODES.NO_SCHOOL_CODE
      })
      await next({ name: 'error' })
    }
  }

  // check if users are refreshing browser
  if (isFromEhub()) {
    resumeApplication()
    return
  }

  if (!to.params.viaEhub) {
    const {
      email,
      cognitoSession,
      idToken,
      refreshToken,
      applicationId,
      isLoggedOut,
      school
    } = store.getters

    // Redirect to landing page when user clicks back button on logout page
    if (isLoggedOut || from.name === 'submitted') {
      next(`/?schoolCode=${school.schoolCode}`)
      return
    }

    removeSessionItem('preForm')

    if (applicationId) {
      next('/submitted')
      store
        .dispatch('resetApplication')
        .then(() => next())
        .catch((err) => {
          store.commit('setError', err)
          next('/error')
        })
      store
        .dispatch('logout')
        .then(() => next())
        .catch((err) => {
          store.commit('setError', err)
          next('/error')
        })
      store.dispatch('setIsValidStudent', { isValid: null })
      store.dispatch('setIsValidParentCarers', { isValid: null })
      store.dispatch('setIsValidSiblings', { isValid: null })
      store.dispatch('setIsValidConsiderations', { isValid: null })
      return
    }

    // Redirect unauthenticated users to the login view. This is especially useful for users who may
    // bookmark individual form steps.
    if (to.params.viaServiceNSW) {
      if (!email || !idToken || !refreshToken) {
        next('/login')
        return
      }
    } else if (!email || !cognitoSession || !idToken || !refreshToken) {
      next('/login')
      return
    }
  }

  store
    .dispatch('initReferenceData', store.getters.form.schoolCode)
    .then(() => {
      store.commit('setIsLoggedOut', false)
      next()
    })
    .catch((err) => {
      store.commit('setError', err)
      next('/error')
    })
}
