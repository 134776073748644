<template>
  <!-- eslint-disable max-len -->

  <div data-testid="welcome-ooa">
    <div v-if="ehubFormData.isLandFromSwsSite">
      <loading-view
        data-testid="ehub-welcome-spinner"
        loading-text="processing, one moment..."
      />
    </div>
    <div v-else>
      <Banner v-if="school" class="ooaBanner">
        <v-card
          :class="$vuetify.breakpoint.xsOnly ? 'mr-4' : 'mr-10'"
          :max-height="$vuetify.breakpoint.xsOnly ? '50' : '118'"
          :max-width="$vuetify.breakpoint.xsOnly ? '50' : '118'"
        >
          <v-img
            :class="$vuetify.breakpoint.xsOnly ? 'imgMoble' : 'imgDesktop'"
            :height="$vuetify.breakpoint.xsOnly ? '38' : '89'"
            :width="$vuetify.breakpoint.xsOnly ? '38' : '89'"
            contain
            :src="school.logo || require('@/assets/schoolPlaceholder.svg')"
            alt="school logo"
            data-testid="school-logo"
          />
        </v-card>
        <div
          :class="{
            mobleTitle: $vuetify.breakpoint.xsOnly,
            tabletTitle: $vuetify.breakpoint.smOnly,
            desktopTtile: $vuetify.breakpoint.mdAndUp
          }"
        >
          <h2 data-testid="school-name">{{ school.schoolName }}</h2>
          <p class="mt-2 mb-0">Out-of-area enrolment application</p>
        </div>
      </Banner>

      <v-container v-if="school" fluid class="ooaContent py-12">
        <!-- section: ehub prefill ? -->
        <ContentBox
          v-if="prefilled"
          class="pt-6"
          data-testid="ehub-prefill"
          img-file-name="icon-pencil-note-pad.svg"
          image-class="largeImage"
          title="We have prefilled details to save you time"
          content="We have completed as many details as possible based on information you have already provided. This will help you complete the enrolment application as quickly as possible."
        />

        <!-- section: Who can apply? -->
        <ContentBox
          class="pt-6"
          img-file-name="icon-whats-online.svg"
          image-class="largeImage"
          title="Who can apply?"
        >
          <template slot="copyContent">
            <p>
              School age children living in NSW who are Australian or New
              Zealand citizens or Australian permanent residents.
            </p>
          </template>
        </ContentBox>

        <!-- section:  Additional learning and support needs -->
        <ContentBox
          class="pt-6"
          img-file-name="additional-learning-support.svg"
          image-class="largeImage"
        >
          <div slot="title">
            <h2 class="pb-2">Additional learning and support needs</h2>
          </div>
          <div slot="copyContent">
            <p>
              If your child has additional learning and support needs, contact
              your
              <a
                href="https://my.education.nsw.gov.au/school-finder"
                target="_blank"
                >local school principal</a
              >
              as soon as possible, before applying out-of-area.
              <a
                href="https://education.nsw.gov.au/teaching-and-learning/disability-learning-and-support/primary/getting-started"
                target="_blank"
                >Read more about inclusive learning support.</a
              >
            </p>
          </div>
        </ContentBox>

        <v-card class="mb-6 pt-2">
          <!-- section: Will the school have a place for my child?
        underCapacity-->
          <ContentBox
            v-if="isUnderCap"
            class="pt-6"
            img-file-name="icon-will-have-space.svg"
            title="Will the school have a place for my child?"
            content="We currently can consider out-of-area applications. However, placement is not guaranteed."
            data-testid="under-capacity"
          />

          <!-- approchingCapacity -->
          <ContentBox
            v-if="isApproachingCap || isNACap"
            class="pt-6"
            img-file-name="icon-will-have-space.svg"
            title="Will the school have a place for my child?"
            data-testid="approaching-capacity"
          >
            <div slot="copyContent">
              <p>
                If places are available after local enrolments have been
                processed we can consider out-of-area applications. Placement is
                not guaranteed for out-of-area students.
              </p>
            </div>
          </ContentBox>

          <!-- overCapacity -->
          <ContentBox
            v-if="isOverCap"
            class="pt-6"
            img-file-name="icon-will-have-space.svg"
            title="Will the school have a place for my child?"
            data-testid="over-capacity"
          >
            <div slot="copyContent">
              <p>
                <strong>Our school has reached its enrolment cap.</strong> As
                per the
                <a
                  href="https://policies.education.nsw.gov.au/policy-library/policies/enrolment-of-students-in-nsw-government-schools"
                  target="_blank"
                  >department's policy</a
                >, we are not considering out-of-area applications unless
                exceptional circumstances apply. <br />Our enrolment cap has
                been set to support student wellbeing, quality education and
                avoid overcrowding.
              </p>
            </div>
          </ContentBox>

          <v-divider />

          <!-- section: How does the school assess applications? -->
          <ContentBox
            v-if="!isOverCap"
            class="pt-6"
            img-file-name="icon-assess.svg"
            title="Assessing applications"
            data-testid="assessing-applications"
          >
            <div slot="copyContent">
              <p class="mb-6" :class="{ 'pb-6': $vuetify.breakpoint.xlOnly }">
                We consider applications that meet one or more of the following
                criteria.
              </p>
              <div v-if="schoolData" class="criteria">
                <div class="criteriaTitle">
                  <img src="@/assets/icon-circle-tick.svg" alt="tick icon" />
                  <h3>Criteria we consider (in order of importance):</h3>
                </div>
                <div class="description">
                  <ul class="criteriaList mb-6" sm="8">
                    <li
                      v-for="(criteria, i) in criteriaWithActiveStatus"
                      :key="i"
                    >
                      {{ criteria.value }}
                    </li>
                  </ul>
                </div>
                <div class="criteriaTitle">
                  <img src="@/assets/icon-circle-cross.svg" alt="cross icon" />
                  <h3 class="errorRed">We will not consider the following:</h3>
                </div>
                <div class="description">
                  <p>
                    Student ability, performance or achievement, as described in
                    the<br />
                    <a
                      href="https://policies.education.nsw.gov.au/policy-library/policies/enrolment-of-students-in-nsw-government-schools"
                      target="_blank"
                      >department's enrolment policy.</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </ContentBox>

          <v-divider />

          <!-- section: When are applications assessed? -->
          <ContentBox
            v-if="schoolData"
            class="pt-6"
            img-file-name="icon-timeframes.svg"
            title="When are applications assessed?"
          >
            <div slot="copyContent">
              <p data-testid="time-frames">
                {{ timeFrameCopy }}
                School holiday periods may increase assessment times.
              </p>
            </div>
          </ContentBox>

          <v-divider />

          <!-- section: Information exchange with currently enrolled and/or local school -->
          <ContentBox
            class="pt-6"
            img-file-name="icon-info-exchange.svg"
            title="Information exchange between schools"
          >
            <div slot="copyContent">
              <p>
                Schools exchange information about online and paper out-of-area
                applications to assist in planning and student care. We may
                share your child's application with:
                <br />- the NSW Government school they currently attend <br />-
                your local NSW Government school.
              </p>
            </div>
          </ContentBox>
        </v-card>
        <div>
          <AdsButton
            class="px-4 float-left mt-5 mb-10"
            color="grey10"
            :secondary="true"
            data-testid="cancel-button"
            button-text="Cancel"
            icon="mdi-close-circle-outline"
            @click="onCancel"
          >
            <v-icon size="20" class="mr-2"> mdi-close-circle-outline </v-icon>
            <span class="h4 mb-0"> Cancel </span>
          </AdsButton>

          <AdsButton
            class="px-4 float-right mt-5 mb-10"
            color="primary"
            data-testid="proceed-button"
            button-text="Proceed"
            icon="mdi-logout-variant"
            :disabled="loading"
            @click="onProceed"
          >
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="white"
              size="20"
              class="mr-2"
            />

            <v-icon v-else color="white" size="20" class="mr-2">
              mdi-logout-variant
            </v-icon>

            <span class="h4 mb-0"> Proceed </span>
          </AdsButton>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import ContentBox from '@/components/ContentBox'
import { AdsButton } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import { getGtmApplication } from '@/helpers'
import LoadingView from '@/components/FullScreenLoader'
import moment from 'moment'
import { PREFILL_FLAG } from '@/constants'
import store from '@/store'

const emptyAddress = {
  addressLine1: null,
  addressLine2: null,
  countryCode: null,
  postCode: null,
  stateCode: null,
  suburbName: null,
  validationFlag: 'N'
}

export default {
  name: 'EhubWelcome',
  components: {
    Banner,
    ContentBox,
    AdsButton,
    LoadingView
  },
  data() {
    return {
      address: window.sessionStorage.getItem('address'),
      entries: [],
      loading: true
    }
  },
  computed: {
    ...mapGetters([
      'school',
      'schoolData',
      'isOverCap',
      'isUnderCap',
      'isApproachingCap',
      'isNACap',
      'ehubPrefill',
      'ehubFormData',
      'prefillParentStudent',
      'parentId',
      'childSrn',
      'email',
      'swsEnrolmentData'
    ]),
    criteriaWithActiveStatus() {
      return this.schoolData
        ? this.schoolData.criteria.filter((c) => c.active)
        : []
    },
    timeFrameCopy() {
      if (
        this.schoolData.currentYearTimeFrame.code !==
        this.schoolData.nextYearTimeFrame.code
      ) {
        return `${this.schoolData.currentYearTimeFrame.description} ${this.schoolData.nextYearTimeFrame.description}`
      }
      const copy = this.schoolData.currentYearTimeFrame.value
      // values are stored like 'In November this year' so lowerCase the first letter only
      return `We will assess your application ${
        copy.charAt(0).toLowerCase() + copy.slice(1)
      } and contact you.`
    },
    prefilled() {
      return !!(this.ehubPrefill && this.ehubPrefill !== PREFILL_FLAG.NO_DATA)
    }
  },
  async created() {
    const schoolCode = window.sessionStorage.getItem('schoolCode')

    if (!schoolCode) return

    await this.getSchoolDetails(schoolCode)

    this.preparePrefillData()
  },
  mounted() {
    if (this.ehubFormData.isLandFromSwsSite) {
      this.goToForm()
    }
  },
  methods: {
    async getSchoolDetails(schoolCode) {
      await this.$store
        .dispatch('isValidSchool', schoolCode)
        .then(() => {
          this.loading = false
        })
        .catch(() => {
          this.$router.push({ name: 'error' })
        })
    },
    async onProceed() {
      // Google Analytics
      this.$gtm.trackEvent({
        event: 'interaction',
        category: 'Form Attempt',
        action: 'start',
        label: 'ooa',
        application: getGtmApplication(true)
      })
      await this.goToForm()
    },
    async onCancel() {
      window.location.href = `${window.location.origin}/enrol/new`
    },
    async goToForm() {
      await this.$store.dispatch('deleteApplication')
      await this.$store
        .dispatch('createNewApplicationViaEhub')
        .then((response) => {
          if (
            response &&
            (response.status === 200 ||
              response.status === 201 ||
              response.status === 202)
          ) {
            this.$router.push({ name: 'form', params: { viaEhub: true } })
          } else {
            this.$router.push({ name: 'error' })
          }
        })
        .catch(() => {
          this.$router.push({ name: 'error' })
        })
    },
    convertToOoaForm(formData) {
      return {
        student: {
          firstName: formData.child?.firstName,
          familyName: formData.child?.lastName,
          srn: formData.child?.srn
        },
        otherStudentDetails: {
          scholasticYear: formData.scholasticYear
        },
        residentialAddress: formData.address,
        isLandFromSwsSite: formData.isLandFromSwsSite
      }
    },
    prefillPrevAttendSchool(prefillData) {
      let prevAttendSchool
      if (prefillData && prefillData.prevAttendSchool === true) {
        prevAttendSchool = {}
        prevAttendSchool.prevAttendSchool =
          prefillData.prevAttendSchool === true ? 'Yes' : 'No'
        prevAttendSchool.prevSchoolType = prefillData.prevSchoolType
        if (
          prefillData.prevSchoolId &&
          prefillData.prevSchoolId.includes('|')
        ) {
          const prevSchoolObj = prefillData.prevSchoolId.split('|')
          if (Array.isArray(prevSchoolObj) && prevSchoolObj.length > 1) {
            prevAttendSchool.prevSchool = {
              value: prevSchoolObj[0],
              text: prevSchoolObj[1]
            }
          }
        }
      }
      return prevAttendSchool
    },
    prefillParentCarer(parentCarer, index, parentId, emailFromIdToken) {
      const parentPrefillData = {
        parentCarerRelation: '',
        parentCarerTitle: '',
        parentCarerFamilyName: '',
        parentCarerGivenName: '',
        parentCarerEmail: '',
        liveOnResidentialAddress: true, // default set to true
        parentCarerCompletingApp: false, // default set to false
        residentialAddress: { ...emptyAddress }, // residentialAddress
        contactDetails: [
          {
            contactType: null,
            contactValue: null,
            comments: null
          }
        ]
      }
      parentPrefillData.parentCarerRelation = parentCarer.parentCarerRelation
      parentPrefillData.parentCarerTitle = parentCarer.parentCarerTitle
      parentPrefillData.parentCarerFamilyName =
        parentCarer.parentCarerFamilyName
      parentPrefillData.parentCarerGivenName = parentCarer.parentCarerGivenName

      if (parentCarer.parentCarerEmail) {
        parentPrefillData.parentCarerEmail = parentCarer.parentCarerEmail
      } else if (index === 0) {
        parentPrefillData.parentCarerEmail = emailFromIdToken
      }

      if (
        Array.isArray(parentCarer.contactDetails) &&
        parentCarer.contactDetails?.length
      ) {
        parentPrefillData.contactDetails = parentCarer.contactDetails.filter(
          (c) => c.contactType !== 'EMAIL'
        )
      }

      if (parentCarer.liveOnResidentialAddress !== undefined) {
        parentPrefillData.liveOnResidentialAddress =
          parentCarer.liveOnResidentialAddress
      }

      if (
        !parentCarer.liveOnResidentialAddress &&
        parentCarer.residentialAddress
      ) {
        parentPrefillData.liveOnResidentialAddress = false
        parentPrefillData.residentialAddress = parentCarer.residentialAddress
      }

      if (parentCarer.parentCarerCompletingApp !== undefined) {
        parentPrefillData.parentCarerCompletingApp =
          parentCarer.parentCarerCompletingApp
      } else if (
        parentCarer.parentCarerEmail &&
        emailFromIdToken &&
        String(parentCarer.parentCarerEmail).toLowerCase() ===
          String(emailFromIdToken).toLowerCase()
      ) {
        parentPrefillData.parentCarerCompletingApp = true
      }
      if (parentPrefillData.parentCarerCompletingApp === true) {
        // Fixed defect FUS-1469 parent id need to be add for primary parent.
        parentPrefillData.parentId = parentId
      }
      parentPrefillData.isEnrolmentOwner = parentCarer.isEnrolmentOwner
      return parentPrefillData
    },
    prefillStudent(student, childSrn) {
      return {
        firstName: student.firstName,
        otherName: student.otherName,
        familyName: student.familyName,
        prefFirstName: student.prefFirstName,
        dateOfBirth: student.dateOfBirth,
        srn: childSrn,
        genderCode: student.genderCode
      }
    },
    prefillOtherStudentDetails(student, scholasticYear) {
      return {
        prevAttendSchool: student.prevAttendSchool === true ? 'Yes' : 'No',
        prevSchoolType: student.prevSchoolType,
        prevSchoolStartDate: student.prevSchoolStartDate
          ? moment(student.prevSchoolStartDate, 'YYYY-MM-DD').format('MM-YYYY')
          : null,
        prevSchoolLastDate: student.prevSchoolLastDate
          ? moment(student.prevSchoolLastDate, 'YYYY-MM-DD').format('MM-YYYY')
          : null,
        scholasticYear,
        prevSchool: {
          text: student.prevSchoolName,
          value: student.prevSchoolCode
        }
      }
    },
    preparePrefillData() {
      const ooaForm = this.convertToOoaForm(this.ehubFormData)
      const prefillData = this.prefillParentStudent
      let ehubPrefill = PREFILL_FLAG.NO_DATA

      if (
        prefillData &&
        Array.isArray(prefillData.parentCarers) &&
        prefillData.parentCarers.length
      ) {
        ehubPrefill = PREFILL_FLAG.PARENT
        let prefillDataParentCarers = prefillData.parentCarers
        // take only two parents if there are more than 3 parens, but the owner parent take the priority.
        const ownerParentCarers = prefillDataParentCarers.filter(
          (e) => e.isEnrolmentOwner === true
        )
        const noneOwnerParentCarers = prefillDataParentCarers.filter(
          (e) => e.isEnrolmentOwner !== true
        )
        const reOrderedParentCarers = ownerParentCarers.concat(
          noneOwnerParentCarers
        )

        if (
          Array.isArray(reOrderedParentCarers) &&
          reOrderedParentCarers.length > 2
        ) {
          prefillDataParentCarers = reOrderedParentCarers.slice(0, 2)
        } else {
          prefillDataParentCarers = reOrderedParentCarers
        }
        ooaForm.parentCarers = prefillDataParentCarers.map(
          (parentCarer, index) =>
            this.prefillParentCarer(
              parentCarer,
              index,
              this.parentId,
              this.email
            )
        )
      }

      if (prefillData && prefillData.student) {
        ehubPrefill =
          ehubPrefill === PREFILL_FLAG.PARENT
            ? PREFILL_FLAG.STUDENT_AND_PARENT
            : PREFILL_FLAG.STUDENT
        ooaForm.student = this.prefillStudent(
          prefillData.student,
          this.childSrn || this.ehubFormData.child.srn
        )
        ooaForm.otherStudentDetails = this.prefillOtherStudentDetails(
          prefillData,
          this.ehubFormData.scholasticYear
        )
        ooaForm.residencyStatus = prefillData.residencyStatus
      }

      // sws path prevAttendSchool prefill
      const swsPrevAttendSchool = this.prefillPrevAttendSchool(
        this.swsEnrolmentData
      )
      if (swsPrevAttendSchool) {
        ooaForm.otherStudentDetails.prevAttendSchool =
          swsPrevAttendSchool.prevAttendSchool
        ooaForm.otherStudentDetails.prevSchoolType =
          swsPrevAttendSchool.prevSchoolType
        ooaForm.otherStudentDetails.prevSchool = swsPrevAttendSchool.prevSchool
      }
      // normal path prevAttendSchool prefill
      const prevAttendSchool = this.prefillPrevAttendSchool(this.ehubFormData)
      if (prevAttendSchool) {
        ooaForm.otherStudentDetails.prevAttendSchool =
          prevAttendSchool.prevAttendSchool
        ooaForm.otherStudentDetails.prevSchoolType =
          prevAttendSchool.prevSchoolType
        ooaForm.otherStudentDetails.prevSchool = prevAttendSchool.prevSchool
      }

      store.commit('setEhubPrefill', ehubPrefill)
      store.commit('setForm', ooaForm)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-search {
  width: 100%;
}
.errorRed {
  color: $ads-error-red;
}
.criteria {
  img {
    position: absolute;
    left: 60px;
  }
  h3 {
    font-size: 1rem;
  }
}
.mobile .criteria {
  text-align: left;
  .criteriaTitle {
    display: flex;
    align-items: center;
  }
  img {
    position: relative;
    left: 0;
    float: left;
    margin-right: 8px;
  }
  .description {
    clear: both;
    padding-top: 20px;
  }
}
.catchmentFinder {
  padding: 12px 0;
}
::v-deep .ooaBanner .container--fluid {
  display: flex;
}
::v-deep .v-sheet.v-card {
  border-radius: 0;
}

.imgMoble {
  margin: 0.3rem;
}

.imgDesktop {
  margin: 1rem;
}

.mobleTitle {
  margin-top: 4px;
}

.tabletTitle {
  margin-top: 26px;
  h2 {
    font-size: 30px !important;
  }
  p {
    font-size: 18px !important;
  }
}

.desktopTtile {
  margin-top: 12px;
}
</style>
