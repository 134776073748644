/* eslint-disable no-param-reassign */
import schoolApi from 'api-client/school'

const schoolModule = {
  state: {
    pilotSchools: null,
    school: null, // url school code data from pilot json
    schoolData: null, // url school code capacity/criteria data
    inCatchment: null,
    residentialAddressFormatted: null, // catchment finder API school address data
    localResidentialAddress: null, // the applying students residential address
    calendarYear: null, // selected calendar year on landing page
    scholasticYear: null, // selected scholastic year on landing page
    selectedCatchment: null, // catchment when selected on the welcome screen
    selectedResidentialAddress: null // the address object selected in the catchment finder
  },
  getters: {
    pilotSchools: (state) => state.pilotSchools,
    // eslint-disable-next-line eqeqeq
    pilotSchool: (state) => (schoolCode) =>
      state.pilotSchools.find((ps) => ps.schoolCode == schoolCode),
    school: (state) => state.school,
    schoolData: (state) => state.schoolData,
    residentialAddressFormatted: (state) => {
      const address = state.localResidentialAddress
      if (address) {
        return `${address.addressLine1}, ${address.suburbName}, ${address.stateCode} ${address.postCode}`
      }
      return ''
    },
    localResidentialAddress: (state) => state.localResidentialAddress,
    inCatchment: (state) => state.inCatchment,
    calendarYear: (state) => state.calendarYear,
    scholasticYear: (state) => state.scholasticYear,
    isOverCap: (state) => !!(state.schoolData?.capacity === 'Over'),
    isUnderCap: (state) => !!(state.schoolData?.capacity === 'Under'),
    isNACap: (state) => !!(state.schoolData?.capacity === 'NA'),
    isApproachingCap: (state) => !!(state.schoolData?.capacity === 'Approach'),
    catchment: (state) =>
      state.selectedCatchment || state.school?.catchmentLevel,
    selectedCatchment: (state) => state.selectedCatchment,
    selectedResidentialAddress: (state) => state.selectedResidentialAddress
  },
  mutations: {
    setPilotSchools(state, pilotSchools) {
      state.pilotSchools = pilotSchools
    },
    setSchool(state, school) {
      state.school = school
    },
    setSchoolData(state, { globalRefs, webCustomisations }) {
      const schoolData = {
        capacity: state.school && state.school.capStatus
      }
      const webCustomisationsOOA = webCustomisations?.ooa

      if (globalRefs) {
        if (webCustomisationsOOA) {
          const schoolCriterion = webCustomisationsOOA.criteria.filter(
            (c) => c.enabled
          )

          // gimme all criterion
          // with an active flag = (global active && school enabled)
          // sorted by the school's custom sort order
          const globalCriterionMapped = globalRefs.criteria
            .map((c) => {
              const matchingCriteria = schoolCriterion.find(
                (gc) => gc.code === c.code
              )
              c.active = c.active === true && matchingCriteria != null
              c.sortOrder = matchingCriteria?.order || 9999
              // renaming description from SI settings to text as we capture user entered value to the criteria.description in application
              c.text = matchingCriteria?.description
              return c
            })
            .sort((c1, c2) => c1.sortOrder - c2.sortOrder)

          Object.assign(schoolData, {
            capacity:
              schoolData.capacity === 'NA'
                ? 'NA'
                : webCustomisationsOOA.capacity.code,
            captureSiblingInfo: webCustomisationsOOA.captureSiblingInfo,
            criteria: globalCriterionMapped,
            // eslint-disable-next-line max-len
            currentYearTimeFrame: globalRefs.currentYearTimeFrames.find(
              (tf) =>
                tf.active === true &&
                tf.code === webCustomisationsOOA.currentYearTimeFrames?.code
            ),
            // eslint-disable-next-line max-len
            nextYearTimeFrame: globalRefs.nextYearTimeFrames.find(
              (tf) =>
                tf.active === true &&
                tf.code === webCustomisationsOOA.nextYearTimeFrames?.code
            ),
            enableOtherCriteria:
              webCustomisationsOOA.enableOtherCriteria == null
                ? true
                : webCustomisationsOOA.enableOtherCriteria
          })
        } else {
          // fallback to defaults in globalRefs
          const defaultCriterion = globalRefs.criteria
            .filter((criteria) => !criteria.allowReorder) // only include locked criteria by default
            .sort((c1, c2) => c1.defaultOrder - c2.defaultOrder)

          Object.assign(schoolData, {
            criteria: defaultCriterion,
            currentYearTimeFrame: globalRefs.currentYearTimeFrames.find(
              (tf) => tf.default
            ),
            nextYearTimeFrame: globalRefs.nextYearTimeFrames.find(
              (tf) => tf.default
            ),
            enableOtherCriteria: true
          })
        }
      }

      state.schoolData = schoolData
    },
    setLocalResidentialAddress(state, address) {
      state.localResidentialAddress = address
    },
    setSelectedResidentialAddress(state, addressObj) {
      state.selectedResidentialAddress = addressObj
    },
    setInCatchment(state, inCatchment) {
      state.inCatchment = inCatchment
    },
    setCalendarYear(state, calendarYear) {
      state.calendarYear = calendarYear
    },
    setScholasticYear(state, scholasticYear) {
      state.scholasticYear = scholasticYear
    },
    setSelectedCatchment(state, catchment) {
      state.selectedCatchment = catchment
    }
  },
  actions: {
    async isValidSchool({ commit, dispatch }, schoolCode) {
      await schoolApi.getPilotSchools().then((res) => {
        commit('setPilotSchools', res)
        const school = res.find(
          (school) => school.schoolCode === schoolCode && school.ooa
        )
        if (school) {
          commit('setSchool', school)
          dispatch('getSchoolCustomisations', schoolCode)
        } else {
          // TODO: handle error for not finding the school.
        }
      })
    },
    async getSchoolCustomisations({ commit }, schoolCode) {
      const promises = []
      promises.push(schoolApi.getGlobalWebContentRef())
      promises.push(schoolApi.getWebCustomisation(schoolCode))

      await Promise.all(promises).then(([globalRefs, webCustomisations]) => {
        commit('setSchoolData', { globalRefs, webCustomisations })
      })
    }
  }
}

export default schoolModule
