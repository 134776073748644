<template>
  <div>
    <FileUpload
      ref="fileUpload"
      v-model="files"
      label="Supporting documents (optional)"
      :max-file-count="fileLimit"
      :max-file-size-mb="sizeLimit"
      :mime-types="mimeTypes"
      :hint="hint"
      placeholder="Upload files..."
      @validationError="handleUploadValidationError"
      @upload="uploadFile"
      @delete="deleteFile"
    />
    <div
      class="alertsWrapper"
      :class="{ mobile: $vuetify.breakpoint.smAndDown }"
      role="alert"
      aria-live="assertive"
    >
      <Alert
        v-for="(alert, i) in alerts"
        :key="i"
        in-page
        :show-alert="true"
        type="error"
        :icon="alert.icon"
        :text="alert.title"
        absolute
      >
        <template slot="optional">
          <!-- eslint-disable-next-line -->
          <div v-html="alert.html" />
          <v-btn
            ref="alertButton"
            icon
            class="snackbarCloseButton"
            aria-label="close"
            @click="closeError(i)"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </template>
      </Alert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  FILE_UPLOAD_ERROR_TYPES,
  getFileUploadError,
  getFileRemoveError
} from '@/constants'
import { Alert } from '@nswdoe/doe-ui-core'
import FileUpload from './form/FileUpload.vue'

export default {
  name: 'SupportingDocumentUpload',
  components: {
    FileUpload,
    Alert
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    isBusy: {
      type: Boolean,
      default: false
    },
    category: {
      // document category
      type: String,
      default: 'MISC'
    },
    mimeTypes: {
      type: String,
      default: 'image/jpg,image/jpeg,image/png,application/pdf'
    },
    hint: {
      type: String,
      default:
        'Supported file types: JPG, PNG or PDF. 10mb limit per file. 3 files maximum.'
    },
    fileLimit: {
      type: Number,
      default: 3
    },
    sizeLimit: {
      type: Number,
      default: 10 // in MB
    }
  },
  data() {
    return {
      alerts: [],
      files: this.value,
      operationsInProgress: 0,
      documentGroupId: new Date().getTime()
    }
  },
  computed: {
    ...mapGetters(['idToken', 'timestamp']),
    fileTypesListHtml() {
      // convert mime types to file types list for display
      // image/jpg,application/pdf => <strong>jpg</strong>, or <strong>pdf</strong
      const types = this.mimeTypes
        .split(',')
        .map((mime) => `<strong>${mime.split('/')[1]}</strong>`)
      if (types.length > 1) {
        types[types.length - 1] = `or ${types[types.length - 1]}`
      }
      return types.join(', ')
    }
  },
  watch: {
    files(val) {
      this.$emit('input', val, this.category)
    },
    value(val) {
      this.files = val
    },
    operationsInProgress(val) {
      if (val) {
        this.$emit('update:isBusy', true)
      } else {
        this.$emit('update:isBusy', false)
      }
    },
    alerts() {
      this.$nextTick(() => {
        if (this.$refs.alertButton) {
          this.$refs.alertButton.forEach((btn) => {
            if (btn) {
              btn.$el.focus()
            }
          })
        }
      })
    }
  },
  mounted() {
    this.$store.commit('setTimestamp', this.documentGroupId)
  },
  methods: {
    uploadFile(file, { progress, success, failure }) {
      this.operationsInProgress += 1
      this.$store.dispatch('setIsFileUploadInProgress', { inProgress: true })
      this.$store
        .dispatch('uploadSupportingDocument', {
          file,
          path: this.documentGroupId,
          category: this.category,
          progressCallback: progress
        })
        .then(() => {
          success({
            name: file.name,
            objectKey: `/${this.category}/${this.documentGroupId}/${file.name}`
          })
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err)
          this.handleUploadValidationError({ fileName: file.name })
          failure(file)
        })
        .finally(() => {
          this.operationsInProgress -= 1
          this.$store.dispatch('setIsFileUploadInProgress', {
            inProgress: false
          })
        })
    },
    deleteFile(file, { success, failure }) {
      this.operationsInProgress += 1
      this.$store.dispatch('setIsFileDeleteInProgress', { inProgress: true })
      this.$store
        .dispatch('deleteSupportingDocument', {
          file,
          category: this.category,
          path: this.documentGroupId
        })
        .then(() => {
          success(file)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err)
          this.handleDeleteValidationError(file.name)
          failure(file)
        })
        .finally(() => {
          this.operationsInProgress -= 1
          this.$store.dispatch('setIsFileDeleteInProgress', {
            inProgress: false
          })
        })
    },
    handleUploadValidationError({ type, fileName }) {
      const errorReasons = {
        [FILE_UPLOAD_ERROR_TYPES.FILE_SIZE]: {
          reason: 'it exceeds the maximum file size of <strong>10mb</strong>.',
          action: 'Please choose a smaller document and try again.'
        },
        [FILE_UPLOAD_ERROR_TYPES.FILE_TYPE]: {
          reason: 'it is an invalid file type.',
          action: `Please choose a file of type ${this.fileTypesListHtml} and try again.`
        },
        [FILE_UPLOAD_ERROR_TYPES.FILE_NAME]: {
          reason: 'this is not a valid file name.',
          action:
            'Please remove symbols and non-alphanumeric characters from the file name and try again.'
        },
        [FILE_UPLOAD_ERROR_TYPES.EXCEED_MAX_FILES]: {
          reason: 'you have exceeded the maximum number of documents.'
        }
      }

      const reason = errorReasons[type]
        ? errorReasons[type].reason
        : 'of a server error.'

      const action = errorReasons[type]
        ? errorReasons[type].action || ''
        : 'Please try uploading the file again.'

      if (reason) {
        if (type === FILE_UPLOAD_ERROR_TYPES.EXCEED_MAX_FILES && fileName) {
          for (let i = 0; i < fileName.length; i += 1) {
            const file = fileName[i]
            // Notifications added immediately after file explorer closes need a delay in order for some assistive technologies to pick up the notification.
            this.pushNotification(getFileUploadError(file, reason, action), 500)
          }
        } else {
          // Notifications added immediately after file explorer closes need a delay in order for some assistive technologies to pick up the notification.
          this.pushNotification(
            getFileUploadError(fileName, reason, action),
            500
          )
        }
      }
    },
    handleDeleteValidationError(fileName) {
      this.pushNotification(getFileRemoveError(fileName))
    },
    pushNotification(notification, delay) {
      if (delay) {
        setTimeout(() => {
          this.alerts.push(notification)
        }, delay)
      } else {
        this.alerts.push(notification)
      }
    },
    closeError(i) {
      this.alerts.splice(i, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.alertsWrapper {
  position: fixed;
  left: 20px;
  bottom: 10px;
  max-width: 50vw;
  z-index: 10;
  &.mobile {
    max-width: 100vw;
    margin-right: 20px;
  }
}

::v-deep .v-alert .v-alert__wrapper {
  .snackbar--text {
    margin-bottom: 8px;
    ~ span {
      line-height: 1.3rem;
    }
  }
  .v-alert__content {
    margin-right: 35px;
  }
  .snackbarCloseButton {
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
button.snackbarCloseButton.v-btn.v-btn--flat.v-btn--icon.v-btn--round.theme--light.v-size--default:focus {
  border: 2px solid $ads-navy;
}
</style>
